<template>
  <section
    class="relative -mt-20 text-left"
  >
    <div
      class="absolute top-0 left-0 right-0 bottom-auto w-full"
    >
      <svg
        class="absolute bottom-0"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          class="fill-current text-pinkish-500"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
    <div class="relative py-10 text-white bg-pinkish-500">
      <div class="container px-4 mx-auto">
        <ul class="space-y-2">
          <li v-for="(item, index) in data.dedicated" :key="index" class="flex py-2 space-x-4 lg:space-x-12">
            <img :src="'/img/facilities/' + item[0]" alt="facalities" width="100" height="100" />
            <p class="flex flex-wrap content-center text-xl font-bold lg:text-3xl">{{item[1]}}</p>
          </li>
        </ul>
        <!--<div class="flex flex-wrap items-center">
          <div
            class="w-full px-4 ml-auto mr-auto lg:w-6/12 z-10 mb-2"
            data-aos="fade-right"
          >
            <div data-tilt data-tilt-glare data-tilt-max-glare="0.8" data-tilt-scale="1.1" class="js-tilt rounded-lg">
              <img
                class="max-w-full rounded-lg shadow-lg"
                alt="hos"
                src="/img/hero.webp"
              />
            </div>
          </div>
          <div
            class="w-full px-4 ml-auto mr-auto md:w-6/12 lg:w-5/12"
            data-aos="fade-left"
          >
            <div class="md:pr-12">
              <ul>
                <li v-for="(item, index) in data.dedicated" :key="index" class="flex py-2">
                  <span class="h-5 w-5 mr-3">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-3" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                    </svg>
                  </span> <p class="text-xl lg:text-2xl">{{ item }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>-->
      </div>
    </div>
    <div
      class="absolute bottom-0 left-0 right-0 top-auto w-full"
      style="z-index:-1"
    >
      <svg
        class="absolute top-0"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          class="fill-current text-pinkish-500"
          points="2560 0 0 100 0 0"
        ></polygon>
      </svg>
    </div>
  </section>
</template>
<script>
export default {
  computed: {
    data () {
      return this.$store.state.home
    }
  }
}
</script>
<style scoped>
img {
  fill: #fff;
}
</style>
