module.exports = {
  purge: [
    './src/**/*.vue',
    './src/**/*.js'
  ],
  darkMode: false, // or 'media' or 'class'
  theme: {
    extend: {
      colors: {
        violet: {
          50: '#f9f2f9',
          100: '#f3e6f3',
          200: '#e2bfe2',
          300: '#d199d1',
          400: '#ae4dae',
          500: '#8b008b',
          600: '#7d007d',
          700: '#680068',
          800: '#530053',
          900: '#440044'
        },
        pinkish: {
          50: '#fcf3f8',
          100: '#fae8f1',
          200: '#f2c5dc',
          300: '#e9a3c6',
          400: '#d95d9c',
          500: '#c91871',
          600: '#b51666',
          700: '#971255',
          800: '#790e44',
          900: '#620c37'
        }
      }
    }
  },
  variants: {
    extend: {
      borderColor: ['active'],
      display: ['group-hover'],
      translate: ['group-hover'],
      opacity: ['group-hover'],
      rotate: ['group-hover'],
      // textColor: ['hover', 'group-hover', 'active'],
      width: ['hover', 'group-hover'],
      height: ['hover', 'group-hover'],
      ringWidth: ['hover'],
      padding: ['hover'],
      margin: ['hover']
    }
  },
  plugins: []
}
