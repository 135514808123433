<template>
  <div v-appear:top="true" class="flex items-center min-h-screen px-5 bg-gray-50">
    <div class="w-full max-w-4xl p-5 mx-auto bg-white rounded-lg shadow-lg">
      <div class="absolute left-0 z-20 w-full px-2 bottom-2 sm:bottom-auto sm:top-2">
        <div v-if="errors.length" v-appear:top="{ duration: 300 }" class="flex items-center max-w-2xl px-2 mx-auto text-red-300 bg-red-600 rounded-md hover:text-red-200">
          <div class="m-1 bg-red-700 rounded-md">
            <svg class="flex-none w-8 h-8 m-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="flex-grow mx-2 my-1">
            <b>Please correct the following error(s):</b>
            <ul class="text-white">
              <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
            </ul>
          </div>
          <button @click="errorClose" class="m-2 mr-0 text-white rounded-md hover:bg-red-500">
            <svg class="flex-none w-8 h-8 m-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
      <div v-appear:top="true" class="relative">
        <div class="absolute top-0 flex justify-between w-full text-gray-200">
          <button @click="$router.go(-1)">
            <svg class="w-5 h-5 hover:text-violet-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
            </svg>
          </button>
          <router-link to="/">
            <svg class="w-5 h-5 hover:text-violet-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </router-link>
        </div>
        <div class="w-full pt-1 pb-5">
          <div class="flex items-center justify-center w-20 h-20 mx-auto -mt-16 overflow-hidden text-white rounded-full shadow-lg bg-violet-500">
            <svg class="w-7 h-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
              <path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd" />
            </svg>
          </div>
        </div>
        <div>
          <Progress :tab="tab" />
          <div class="pb-4">
            <div v-if="tab === 0" class="space-y-1.5 pt-4">
              <p class="font-bold">
                <span class="text-pinkish-500">Mobile No.:</span> {{phoneNumber}}
              </p>
              <label for="full_name" class="sr-only">Full Name</label>
              <input id="full_name" name="full_name" type="text" v-model="full_name" placeholder="Full Name" required class="relative w-full px-3 py-2 border rounded-md sm:text-sm focus:border-violet-500 focus:z-10" />
            </div>
            <div v-if="tab === 1">
              <Calendar :calendar="calendar" :idToken="idToken" @updateCalendar="updateCalendar" />
              <p class="text-left">*Double Click the date to select</p>
            </div>
            <div v-if="tab === 2">
              <label class="font-bold"><span class="text-pinkish-500">Available Slot:</span>&nbsp;{{selectDate}}</label>
              <ul class="flex flex-wrap justify-center mt-3">
                <li v-for="(item, index) in wholeList" :key="index" @click="changeTimeSelect(item)" :class="timeSelect === item ? 'bg-violet-500 hover:bg-violet-700 focus:ring-violet-500 ring-violet-500 ring-2 ring-offset-2 px-3 my-1 py-1' : 'bg-pinkish-500 hover:bg-pinkish-700 px-3 py-2 focus:ring-pinkish-500'" class="relative flex justify-center text-sm font-medium text-white border border-transparent rounded-md group focus:ring-2 focus:ring-offset-2 m-1 font-bold">
                  {{ item }}
                </li>
              </ul>
            </div>
            <div v-if="tab === 3" class="space-y-1.5">
              <label for="remark" class="sr-only">Remark</label>
              <textarea id="remark" name="remark" type="text" rows="8" v-model="remark" placeholder="Remark" required class="relative w-full px-3 py-2 border rounded-md sm:text-sm focus:border-violet-500 focus:z-10" />
            </div>
          </div>
          <div class="flex justify-center space-x-2">
            <button v-if="tab" @click="switching(-1)" class="relative flex justify-center w-6/12 px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-pinkish-500 group hover:bg-pinkish-700 focus:ring-2 focus:ring-offset-2 focus:ring-pinkish-500">
              <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                <svg class="w-5 h-5 text-pinkish-400 group-hover:text-pinkish-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z" clip-rule="evenodd" />
                </svg>
              </span>
              Previous
            </button>
            <router-link v-else to="/auth" class="relative flex justify-center w-6/12 px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-pinkish-500 group hover:bg-pinkish-700 focus:ring-2 focus:ring-offset-2 focus:ring-pinkish-500">
              <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                <svg class="w-5 h-5 text-pinkish-400 group-hover:text-pinkish-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z" clip-rule="evenodd" />
                </svg>
              </span>
              Change Mobile No.
            </router-link>
            <button v-if="tab !== 3" @click="switching(1)" class="relative flex justify-center w-6/12 px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-violet-500 group hover:bg-violet-700 focus:ring-2 focus:ring-offset-2 focus:ring-violet-500">
              Next
              <span class="absolute inset-y-0 right-0 flex items-center pr-3">
                <svg class="w-5 h-5 text-violet-400 group-hover:text-violet-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clip-rule="evenodd" />
                </svg>
              </span>
            </button>
            <button v-if="tab === 3" @click="submitForm" class="relative flex justify-center w-6/12 px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-violet-500 group hover:bg-violet-700 focus:ring-2 focus:ring-offset-2 focus:ring-violet-500">
              Submit
              <span class="absolute inset-y-0 right-0 flex items-center pr-3">
                <svg class="w-5 h-5 text-violet-400 group-hover:text-violet-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clip-rule="evenodd" />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Progress from '../components/Progress.vue'
import Calendar from '../components/Calendar.vue'
import tm from '../utils/timeSlots'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  components: { Progress, Calendar },
  data: function () {
    return {
      errors: [],
      idToken: null,
      phoneNumber: null,
      calendar: null,
      tab: 0,
      full_name: '',
      wholeList: {},
      selectDate: '',
      timeSelect: '',
      remark: '',
      link: [
        'name',
        'calendar',
        'clock',
        'remark'
      ]
    }
  },
  watch: {
    '$route' (to) {
      if (to.path.split('/')[1] === 'appointment') {
        var tab = to.params.id
        if (!tab) {
          this.$router.push({ path: '/appointment/name' })
        }
        if (tab === 'clock') {
          this.getWholeList()
        }
        let i = 0
        for (i = 0; i < this.link.length; i++) {
          if (tab === this.link[i]) {
            this.tab = i
          }
        }
      }
    }
  },
  mounted () {
    var tab = this.$route.params.id
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        this.$router.push({ path: '/auth' })
      } else {
        this.phoneNumber = user.phoneNumber
        user.getIdToken(true)
          .then((idToken) => {
            this.idToken = idToken
          })
        /* if (this.phoneNumber) {
          fetch('/api/checkBooking/?phone=' + encodeURIComponent(this.phoneNumber), {
            headers: {
              AuthToken: this.idToken
            }
          })
            .then(response => response.json())
            .then(json => {
              localStorage.setItem('Booking', JSON.stringify(json))
              // console.log(json, 'ikl')
            })
        } */
      }
    })
    if (!tab) {
      this.$router.push({ path: '/appointment/name' })
    }
    if (!this.full_name.length) {
      this.$router.push({ path: '/appointment/name' })
    }
    let i = 0
    for (i = 0; i < this.link.length; i++) {
      if (tab === this.link[i]) {
        this.tab = i
      }
    }
  },
  methods: {
    changeTimeSelect: function (e) {
      this.timeSelect = e
    },
    getWholeList: function () {
      if (!this.$route.query.date) {
        this.$router.push({ path: '/appointment' })
      } else {
        this.selectDate = this.$route.query.date
        const startData = new Date(this.$route.query.date)
        const endDate = new Date(startData.getTime() + (24 * 60 * 60 * 1000)) // new Date(this.$route.query.date)
        var cal = this.calendar
        const mainList = []
        for (let i = 0; i < cal.length; i++) {
          if ((this.$route.query.date <= cal[i].end) && (cal[i].start <= tm.baseTime(endDate, true))) {
            mainList.push({
              start: new Date(cal[i].start),
              end: new Date(Date.parse(cal[i].end) - 1000)
            })
          }
        }
        // mor  //eve
        // sun
        var slb = []
        if (!new Date(this.$route.query.date).getDay()) {
          console.log('sun')
          slb = [[0, 539], [720, 1440]]
        } else {
          console.log('all')
          slb = [[0, 659], [840, 1019], [1139, 1440]]
        }
        for (let j = 0; j < mainList.length; j++) {
          const st = new Date(mainList[j].start)
          const en = new Date(mainList[j].end)
          slb.push([
            (st.getHours() * 60) + st.getMinutes(),
            (en.getHours() * 60) + en.getMinutes()
          ])
        }
        this.wholeList = tm.getimeSlots(slb, true, 'quarter')
        // console.log(this.wholeList, 'ww')
        const lis = {}
        for (const k in this.wholeList) {
          // console.log(this.wholeList[k], new Date(this.wholeList[k]).toLocaleString('en-US', { hour12: true }), 'kk')
          const t = this.wholeList[k].split(':')
          lis[k] = (t[0] > 12 ? (t[0] - 12) + ':' + t[1] + ' PM' : t[0] + ':' + t[1] + ' AM')
        }
        this.wholeList = lis
        return lis
      }
    },
    switching: async function (e) {
      this.err = []
      if (e === 1) {
        if (this.tab >= 0) {
          if (!this.full_name.length) {
            return
          }
        }
        if (this.tab >= 2) {
          if (!this.timeSelect.length) {
            return
          }
        }
      } else {
        this.$router.push({ path: '/appointment' })
      }
      if (!this.err.length) {
        if (!((e === -1 && this.tab <= 0) || (e === 1 && this.tab >= 3))) {
          this.tab += e
          this.$router.push({ path: `/appointment/${this.link[this.tab]}` })
        }
      }
    },
    updateCalendar: function (e) {
      this.calendar = e
    },
    convertTime12to24: function (time12h) {
      const [time, modifier] = time12h.split(' ')
      let [hours, minutes] = time.split(':')
      if (hours === '12') {
        hours = '00'
      }
      if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12
      }
      return `${hours}:${minutes}`
    },
    submitForm: async function () {
      var t = tm.baseTime(new Date())
      var data = {
        timeStamp: t.slice(5, 7) + '/' + t.slice(8, 10) + '/' + t.slice(0, 4) + ' ' + t.slice(11, 19),
        phone: this.phoneNumber,
        name: this.full_name,
        selectTime: this.selectDate.slice(5, 7) + '/' + this.selectDate.slice(8, 10) + '/' + this.selectDate.slice(0, 4) + ' ' + this.convertTime12to24(this.timeSelect) + ':00',
        remark: this.remark
      }
      fetch('/api/addEvents', {
        method: 'post',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' }
      })
        .then(response => response.json())
        .then(e => {
          console.log('dd', data)
          if (e === 1) {
            this.$router.push({ name: 'confirmation', params: data })
          }
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    }
  }
}
</script>

<style>
.selectList:focus option:first-of-type {
    display: none;
}
</style>
