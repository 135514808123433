<template>
  <!--<nav
    class="absolute top-0 z-50 flex flex-wrap items-center justify-between w-full px-2 py-3 navbar-expand-lg"
  >
    <div
      class="container flex flex-wrap items-center justify-between px-4 mx-auto"
    >
      <div
        class="relative flex justify-between w-full lg:w-auto lg:static lg:block lg:justify-start"
      >
        <router-link
          class="flex items-center py-2 mr-4 text-lg font-bold leading-relaxed whitespace-no-wrap text-snehdhara-8"
          to="/"
        ><img alt="logo" class="h-16 pr-6" src="../../assets/logo.png" />Snehdahar&nbsp;<span class="text-yellow-500">Women's Hospital</span>
        </router-link><button
          class="block px-3 py-1 text-xl leading-none bg-transparent border border-transparent border-solid rounded outline-none cursor-pointer lg:hidden focus:outline-none"
          type="button"
          onclick="toggleNavbar('example-collapse-navbar')"
        >
          <i class="text-snehdhara-8 fas fa-bars"></i>
        </button>
      </div>

    </div>
  </nav>  :class="wid ? 'mx-0' : 'mx-0'"  :class="!wid && 'mx-auto'"  px-2-->
  <nav class="flex flex-col lg:flex-row container mx-auto justify-between py-2 px-2 z-50">
    <div class="flex justify-between items-center text-lg font-bold text-snehdhara-8">
      <!--<router-link to="/" class="text-left">
        <img src="../../assets/logo.png" alt="logo" class="h-16" />
        <p class="text-xs">{{$store.state.head0}}</p>
        <p>Snehdhara&nbsp;<span class="text-yellow-500">Women's Hospital</span></p>
      </router-link>-->
      <div @click="open = !open " class="lg:hidden">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
        </svg>
      </div>
    </div>
    <ul v-if="wid || open" :class="!wid && 'absolute left-0 right-0 top-20 bg-white py-4 border-t border-b border-violet-500'" class="flex flex-col lg:flex-row items-center py-3 space-x-5 text-pinkish-500 font-bold">
      <li>
        <router-link to="/">
          Home
          <div v-if="$route.name === 'home'" class="h-1 bg-yellow-500" />
        </router-link>
      </li>
      <li>
        <router-link to="/about">About Us</router-link>
        <div v-if="$route.name === 'about'" class="h-1 bg-yellow-500" />
      </li>
      <li>
        <router-link to="/mission_and_vision">
          Mission & Vision
          <div v-if="$route.name === 'mission'" class="h-1 bg-yellow-500" />
        </router-link>
      </li>
      <li v-if="wid" class="relative inline-block text-left">
        <Menu>
          <MenuButton class="font-bold">
            Services
            <div v-if="$route.name === 'services'" class="h-1 bg-yellow-500" />
          </MenuButton>
          <MenuItems class="absolute right-0 w-56 mt-2 py-1 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none">
            <li v-for="(item, index) in treatments" :key="index">
              <router-link :to="'/services/' +item.toLowerCase()">
                <MenuItem v-slot="{ active }">
                  <div class="px-2 py-1" :class="{ 'px-1 bg-pinkish-200': active }">
                    {{item}}
                  </div>
                </MenuItem>
              </router-link>
            </li>
          </MenuItems>
        </Menu>
      </li>
      <li v-else class="bg-pinkish-200 w-full p-2 my-2 rounded-lg">
        <div v-for="(item, index) in treatments" :key="index">
          <router-link :to="'/services/' +item.toLowerCase()">
            {{item}}
          </router-link>
        </div>
      </li>
      <li>
        <router-link to="/gallery">
          Gallery
          <div v-if="$route.name === 'gallery'" class="h-1 bg-yellow-500" />
        </router-link>
      </li>
      <!--<li>
        <router-link to="/appointment" class="inline-flex items-center justify-center px-10 py-3 text-base bg-white border border-transparent rounded-full text-white bg-pinkish-500 hover:bg-yellow-500">
          Contact&nbsp;&nbsp;<i class="flex text-snehdhara-8 fas fa-phone" />
        </router-link>
      </li>-->
    </ul>
    <!--<div class="flex items-center space-x-5 text-pinkish-500 font-bold">
      <Menu>
        <MenuButton class="font-bold">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
          </svg>
        </MenuButton>
        <MenuItems class="absolute left-0 bottom-0 w-56 mt-2 py-1 origin-bottom-left bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none">
          <li v-for="(item, index) in treatments" :key="index">
            <router-link :to="'/services/' +item.toLowerCase()">
              <MenuItem>
                <router-link to="/gallery">
                  Gallery
                  <div v-if="$route.name === 'gallery'" class="h-1 bg-yellow-500" />
                </router-link>
              </MenuItem>
            </router-link>
          </li>
        </MenuItems>
      </Menu>
    </div>-->
  </nav>
</template>
<script>
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../../tailwind.config.js'

const fullConfig = resolveConfig(tailwindConfig)
export default {
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem
  },
  created: function () {
    this.onResize()
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.onResize)
  },
  data: function () {
    return {
      open: false,
      windowWidth: 0
    }
  },
  computed: {
    treatments: function () {
      return this.$store.state.services.list
    },
    wid: function () {
      if (parseInt(fullConfig.theme.screens.lg) > this.windowWidth) {
        return false
      } else {
        return true
      }
      // return this.windowWidth
    }
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>
