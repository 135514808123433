<template>
  <Layout>
    <div class="bg-pinkish-500">
      <div class="text-left container mx-auto px-4 pt-12 font-bold text-2xl text-white">
        Mission & Vision
        <div class="bg-yellow-500 w-8 h-1"/>
      </div>
    </div>
    <div
      class="absolute top-0 left-0 right-0 top-auto w-full"
    >
      <svg
        class="absolute top-0"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          class="fill-current text-pinkish-500"
          points="2560 0 0 100 0 0"
        ></polygon>
      </svg>
    </div>
    <div class="container text-left text-xl mx-auto px-4 my-20">
      <img src="/img/mission-vision.png" alt="Mission and Vision" class="rounded-xl lg:w-6/12 mx-auto" />
      <h2 class="font-bold text-pinkish-500 text-xl pt-4">Mission</h2>
      <ul class="ml-4 pb-6 list-disc">
        <li v-for="(item, index) in data.mission" :key="index" class="flex">
          <svg class="w-5 text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
          </svg>&nbsp;
          {{item}}
        </li>
      </ul>
      <h2 class="font-bold text-pinkish-500 text-xl pt-3">Vision</h2>
      <ul class="ml-4 pb-6 list-disc">
        <li class="flex">
          <svg class="w-5 text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
          </svg>&nbsp;
          {{ data.vision }}
        </li>
      </ul>
      <h2 class="font-bold text-pinkish-500 text-xl pt-3">Values</h2>
      <ul class="ml-4 pb-8 list-disc">
        <li v-for="(item, index) in data.values" :key="index" class="flex">
          <svg class="w-5 text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
          </svg>&nbsp;
          {{item}}
        </li>
      </ul>
    </div>
  </Layout>
</template>

<script>
import Layout from '../layout/default.vue'
export default {
  components: {
    Layout
  },
  computed: {
    data () {
      return this.$store.state.mv
    }
  }
}
</script>
