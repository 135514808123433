<template>
  <Auth>
    <div class="absolute left-0 z-20 w-full px-2 bottom-2 sm:bottom-auto sm:top-2">
      <div v-if="errors.length" v-appear:top="{ duration: 300 }" class="flex items-center max-w-2xl px-2 mx-auto text-red-300 bg-red-600 rounded-md hover:text-red-200">
        <div class="m-1 bg-red-700 rounded-md">
          <svg class="flex-none w-8 h-8 m-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="flex-grow mx-2 my-1">
          <b>Please correct the following error(s):</b>
          <ul class="text-white">
            <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
          </ul>
        </div>
        <button @click="errorClose" class="m-2 mr-0 text-white rounded-md hover:bg-red-500">
          <svg class="flex-none w-8 h-8 m-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </button>
      </div>
    </div>
    <div v-appear:top="true" class="relative">
      <div class="absolute top-0 flex justify-between w-full text-gray-200">
        <button @click="$router.go(-1)">
          <svg class="w-5 h-5 hover:text-violet-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
          </svg>
        </button>
        <router-link to="/">
          <svg class="w-5 h-5 hover:text-violet-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </router-link>
      </div>
      <div class="w-full pt-1 pb-5">
        <div class="flex items-center justify-center w-20 h-20 mx-auto -mt-16 overflow-hidden text-white rounded-full shadow-lg bg-violet-500">
          <svg v-if="!verification" class="w-7 h-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z" />
          </svg>
          <svg v-if="verification" class="w-7 h-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z" clip-rule="evenodd" />
          </svg>
        </div>
      </div>
      <form v-if="!verification" @submit="phoneForm" method="post" novalidate="true" class="space-y-4">
        <h1 class="mb-6 text-2xl font-bold text-center text-violet-600" >Enter Mobile No.</h1>
        <label for="phone" class="sr-only">Phone</label>
        <input id="phone" name="phone" type="tel" v-model="phone" placeholder="+91 (000) 000-0000" v-maska="'+91 (###) ###-####'" required class="relative w-full px-3 py-2 border rounded-md sm:text-sm focus:border-violet-500 outline-none focus:z-10" />
        <div class="space-x-2 flex">
          <router-link to="/" class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-red-500 border border-red-500 rounded-md group hover:bg-red-700 hover:text-white hover:border-red-700 focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
            <span class="absolute inset-y-0 left-0 flex items-center pl-3">
              <svg class="w-5 h-5 text-red-400 group-hover:text-red-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </span>
            Cancel
          </router-link>
          <!--<router-link to="/" class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-red-500 group hover:bg-red-700 focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
            <span class="absolute inset-y-0 left-0 flex items-center pl-3">
              <svg class="w-5 h-5 text-red-400 group-hover:text-red-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </span>
            Cancel
          </router-link>-->
          <button type="submit" class="relative flex font-bold justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-violet-500 group hover:bg-violet-700 focus:ring-2 focus:ring-offset-2 focus:ring-violet-500">
            <span class="absolute inset-y-0 left-0 flex items-center pl-3">
              <svg class="w-5 h-5 text-violet-400 group-hover:text-violet-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z" />
              </svg>
            </span>
            Continue
          </button>
        </div>
      </form>
      <form v-if="verification" @submit="otpForm" method="post" novalidate="true" class="space-y-4">
        <h1 class="mb-6 text-2xl font-bold text-center text-violet-600" >Book an Appointment</h1>
        <p class="text-sm font-semibold text-gray-500">Enter OTP</p>
        <OtpInput
          :numInputs="len"
          :isDisabled="false"
          :hasErrored="false"
          :isInputNum="true"
          separator="-"
          @otp="onOtp"
          :shouldAutoFocus="true"
        />
        <div class="space-y-2">
          <button type="submit" class="relative flex font-bold justify-center w-full max-w-xs px-4 py-2 mx-auto text-sm font-medium text-white border border-transparent rounded-md bg-violet-500 group hover:bg-violet-700 focus:ring-2 focus:ring-offset-2 focus:ring-violet-500">
            <span class="absolute inset-y-0 left-0 flex items-center pl-3">
              <svg class="w-5 h-5 text-violet-400 group-hover:text-violet-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z" clip-rule="evenodd" />
              </svg>
            </span>
            Contniue
          </button>
        </div>
      </form>
    </div>
    <div id="recaptcha-container" />
  </Auth>
</template>

<script>
import Auth from '../layout/auth.vue'
import OtpInput from '../components/OtpInput/index.vue'
import firebase from 'firebase/app'
import 'firebase/auth'
// confirmationResult > verification
export default {
  components: { Auth, OtpInput },
  data: function () {
    return {
      errors: [],
      phone: null,
      len: 6,
      otp: null,
      recaptchaVerifier: null,
      verification: null
    }
  },
  watch: {
    '$route' (to) {
      if (to.params.link === 'otp') {
        this.state = 1
      } else {
        this.state = 0
      }
    }
  },
  mounted () {
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', { size: 'invisible' })
    this.recaptchaVerifier.render()
    if (this.$route.params.link === 'otp' && !this.verification) {
      this.$router.push({ name: 'auth' })
    }
  },
  methods: {
    errorClose: function (e) {
      this.errors = []
    },
    onOtp: function (e) {
      this.otp = e
    },
    phoneForm: async function (e) {
      e.preventDefault()
      this.errors = []
      var p = this.phone.match(/\d+/g)
      var l = p.slice(1).join('').length
      if (!this.phone) {
        this.errors.push('Phone Number required.')
      } else if (l !== 10) {
        this.errors.push('Invalid Phone Number.')
      }
      if (!this.errors.length) {
        firebase.auth().signInWithPhoneNumber(this.phone, this.recaptchaVerifier).then((result) => {
          this.verification = result
          if (result) {
            this.$router.push({ path: '/auth/otp' })
          }
          // console.log('rr', result)
          // this.state = 1
          // this.$router.push({ name: 'authPage', params: { phoneCode: this.phoneCode, phone: this.phoneC, signUp: false } })
        }).catch((error) => {
          // console.log(error, 'eww')
          this.errors.push(error.message)
        })
      }
    },
    otpForm: async function (e) {
      e.preventDefault()
      if (!this.otp) {
        this.errors.push('OTP required.')
      } else if (this.otp.length !== this.len) {
        this.errors.push('Invalid OTP.')
      }
      if (!this.errors.length) {
        // console.log(this.otp, 'ee')
        this.verification.confirm(this.otp).then(async (result) => {
          this.$router.push({ path: '/appointment' })
          // console.log(result, 'rr')
        }).catch((error) => {
          this.errors.push(error.message)
        })
      }
    }
  }
}
</script>

<style>

</style>
