<template>
  <Layout>
    <!-- {{rout}}
    {{data[rout].length}}
    flex justify-between items-center py-16 container mx-auto px-4 font-bold bg-pinkish-500 text-white
    -->

    <div class="bg-pinkish-500">
      <div class="flex justify-between items-center container mx-auto px-4 pt-10 pb-5 font-bold text-white">
        <div class="text-3xl">
          <!-- <span class="underline" style="text-decoration-color: rgba(251, 191, 36, 1)">{{ rout.charAt(0).toUpperCase() }}</span>{{ rout.slice(1) }} -->
          {{ rout.charAt(0).toUpperCase() + rout.slice(1) }}
          <div class="bg-yellow-500 w-8 h-1"/>
        </div>
        <div class="text-xl">
          <router-link to="/" class="text-yellow-400 hover:text-yellow-500">Home</router-link> / {{ rout.charAt(0).toUpperCase() + rout.slice(1) }}
        </div>
      </div>
    </div>
    <div
      class="absolute top-0 left-0 right-0 top-auto w-full"
    >
      <svg
        class="absolute top-0"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          class="fill-current text-pinkish-500"
          points="2560 0 0 100 0 0"
        ></polygon>
      </svg>
    </div>
    <div class="lg:flex my-20 text-xl container mx-auto px-4 py-4 lg:space-x-4 text-left">
      <div class="w-full lg:w-4/12 lg:hidden mb-6">
        <h2 class="px-2 py-3 font-bold text-white bg-pinkish-500">Our Services</h2>
        <ul class="px-2 py-2 font-semibold text-pinkish-500 bg-pinkish-100">
          <li v-for="(item, index) in data.list" :key="index" class="p-1 rounded-md" :class="rout === item.toLowerCase() && 'bg-pinkish-200'">
            <router-link class="flex items-center" :to="'/services/' + item.toLowerCase()">
              <svg class="w-5 text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
              </svg>
              {{item}}
            </router-link>
          </li>
        </ul>
      </div>
      <img :src="'/img/services/' + rout + '.webp'" alt="img" class="w-full lg:w-4/12 rounded-lg">
      <ul class="w-full lg:px-4 lg:my-2 font-semibold text-pinkish-500 lg:w-4/12 py-8 lg:py-0">
        <li class="flex leading-8" v-for="(item, index) in data[rout]" :key="index">
          <svg class="w-5 text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
          </svg>
          {{ item }}
        </li>
      </ul>
      <div class="w-full lg:w-4/12 hidden lg:block">
        <h2 class="px-2 py-3 font-bold text-white bg-pinkish-500">Our Services</h2>
        <ul class="px-2 py-2 font-semibold text-pinkish-500 bg-pinkish-100">
          <li v-for="(item, index) in data.list" :key="index" class="p-1 rounded-md" :class="rout === item.toLowerCase() && 'bg-pinkish-200'">
            <router-link class="flex items-center" :to="'/services/' + item.toLowerCase()">
              <svg class="w-5 text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
              </svg>
              {{item}}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <!--<ul>
      <li v-for="(item, index) in 10" :key="index">
        {{ $t(`services.${rout}[${index}]`) }}
      </li>
    </ul>
    ||||||||||||||||||
    {{$t(`services.${rout}`)}}-->
    <!--<ul>
      <li v-for="(item, index) in $t(`services.${rout}`).length" :key="index">
        {{ item }}
      </li>
    </ul>-->
  </Layout>
</template>

<script>
import Layout from '../layout/default.vue'
export default {
  components: {
    Layout
  },
  computed: {
    rout () {
      return this.$route.params.id
    },
    data () {
      return this.$store.state.services
    }
  }
}
</script>
