import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './index.css'
import AppearDirective from './utils/appear'
import Maska from 'maska'
import runtime from 'serviceworker-webpack-plugin/lib/runtime'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/storage'
// import * as corsModule from "cors"
// const cors = corsModule(optio)
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

if ('serviceWorker' in navigator) {
  runtime.register()
}
// Initialize Firebase
firebase.initializeApp({
  apiKey: 'AIzaSyBasuZyFHZ8eWJADqU_s5UTzpJF342iAyY',
  authDomain: 'snehdhara-312301.firebaseapp.com',
  databaseURL: 'https://snehdhara-312301-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'snehdhara-312301',
  storageBucket: 'snehdhara-312301.appspot.com',
  messagingSenderId: '380328471512',
  appId: '1:380328471512:web:0219801a8c354cc86320ad',
  measurementId: 'G-E0GVRTM0XP'
})
firebase.analytics()

firebase.analytics().logEvent('notification_received')

// fetch('https://api.countapi.xyz/hit/wertwertwer/visits')
fetch('https://api.counterapi.dev/v1/zeus/snd/up')
  .then(response => response.json())
  .then((e) => {
    store.commit('increment', e.count)//e.value)
  })

var storage = firebase.storage().ref('popUp')
storage.listAll()
  .then((res) => {
    const data = res.items[0]
    if (data) {
      const type = data.name.split('.').slice(-1)[0]
      data.getDownloadURL().then((e) => {
        if (type === 'txt' || type === 'html') {
          var d = e// .replace('https://firebasestorage.googleapis.com', '')
          fetch(d).then(response => response.text()).then((c) => {
            store.commit('popUp', { popUp: c, popUpType: type, fileName: data.name.split('.').slice(0, -1).join('.') })
          })
        } else {
          store.commit('popUp', { popUp: e, popUpType: type, fileName: data.name.split('.').slice(0, -1).join('.') })
        }
      })
    }
  }).catch((error) => {
    console.log('ee', error)
  })

VueViewer.setDefaults({
  movable: false,
  rotatable: false,
  scalable: false
})

createApp(App).directive('appear', AppearDirective).use(store).use(Maska).use(VueViewer).use(router).mount('#app')
