import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Mission from '../views/Mission.vue'
import Services from '../views/Services.vue'
import Gallery from '../views/Gallery.vue'
import Contact from '../views/Contact.vue'

import Auth from '../views/Auth.vue'
import Appointment from '../views/Appointment.vue'
import Confirmation from '../views/Confirmation.vue'
// import OTP from '../views/Auth/OTP.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/mission_and_vision',
    name: 'mission',
    component: Mission
  },
  {
    path: '/services/:id',
    name: 'services',
    component: Services
  }, {
    path: '/gallery',
    name: 'gallery',
    component: Gallery
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/auth',
    name: 'auth',
    component: Auth
  },
  {
    path: '/auth/:link',
    name: 'authPage',
    component: Auth//,
    // props: true
  },
  {
    path: '/appointment',
    name: 'appointment',
    component: Appointment
  },
  {
    path: '/appointment/:id',
    name: 'appointmentId',
    component: Appointment
  },
  {
    path: '/booking-confirmation',
    name: 'confirmation',
    component: Confirmation,
    props: true
  }
  /* {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  } */
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
