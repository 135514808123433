<template>
  <Layout>
    <section
      class="relative"
    >
      <div
        class="absolute top-0 left-0 right-0 top-auto w-full"
        style="z-index:-1"
      >
        <svg
          class="absolute top-0"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="fill-current text-pinkish-500"
            points="2560 0 0 100 0 0"
          ></polygon>
        </svg>
      </div>
      <div class="p-8">
        asdf fsddfasd
        adsfasdf
        adsfasdfasdfasdf
        adsfasdfasdfasdfasdfsadfasdf
      </div>
    </section>
  </Layout>
</template>

<script>
import Layout from '../layout/default.vue'
export default {
  components: {
    Layout
  }
}
</script>
