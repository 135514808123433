<template>
  <!--{{calendar}}-->
  <div class="pt-2">
    <div class="flex justify-between py-2">
      <button @click="move(0)" class="font-bold focus:outline-none">{{month_names[month]}} {{year}}</button>
      <div class="flex">
        <button @click="move(-1)" class="focus:outline-none">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
          </svg>
        </button>
        &nbsp;
        <button @click="move(1)" class="focus:outline-none">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </button>
      </div>
    </div>
    <div id="calendar" class="mx-auto"></div>
  </div>
</template>

<script>
import Calendar from 'tui-calendar'
import 'tui-calendar/dist/tui-calendar.css'
import tm from '../utils/timeSlots'
import { RRule } from 'rrule'
// If you use the default popups, use this.
import 'tui-date-picker/dist/tui-date-picker.css'
import 'tui-time-picker/dist/tui-time-picker.css'

export default {
  data: function () {
    return {
      month: null,
      year: null,
      month_names: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      month_names_short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    }
  },
  props: ['calendar', 'idToken'],
  emits: ['updateCalendar'],
  mounted () {
    this.calendarInstance = new Calendar('#calendar', {
      defaultView: 'month',
      useCreationPopup: false,
      taskView: false,
      template: {
        time: function (schedule) {
          const date = new Date(schedule.start)
          // console.log('ttm', date, schedule)
          /* const h = date.getHours()
          const m = date.getMinutes() */
          return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })// '<div class="w-8">' + h + ':' + m + '</div>'
        }
      },
      calendars: [
        {
          id: '1',
          name: 'My Calendar',
          color: '#ffffff',
          bgColor: '#9e5fff',
          dragBgColor: '#9e5fff',
          borderColor: '#9e5fff'
        },
        {
          id: '2',
          name: 'Company',
          color: '#ffffff',
          bgColor: '#00a9ff',
          dragBgColor: '#00a9ff',
          borderColor: '#00a9ff'
        }
      ],
      scheduleView: ['time']
    })
    /// ///////////////////console.log(this.calendar, 'this.calendar')
    /* this.calendarInstance.createSchedules([
      {
        calendarId: '1',
        title: 'my schedule',
        category: 'allday',
        start: new Date('2021-05-18'),
        end: new Date('2021-05-18')
      }]) */
    /* this.calendarInstance.createSchedules(
      // this.calendar
      [{
        calendarId: '1',
        category: 'time',
        // start: dt, // new Date(new Date().setHours(new Date().getHours() + 1)),
        // end: de
        start: '2021-05-18T22:30:00+09:00',
        end: '2021-05-19T02:30:00+09:00'
      }]
    ) *//* [
      {
        calendarId: '1',
        title: 'my schedule',
        category: 'allday'
      },
      {
        calendarId: '1',
        category: 'time',
        start: new Date(new Date().setHours(new Date().getHours() + 1)),
        end: new Date(new Date().setHours(new Date().getHours() + 2))
      },
      {
        calendarId: '1',
        category: 'time',
        start: new Date(new Date().setHours(new Date().getHours() + 2)),
        end: new Date(new Date().setHours(new Date().getHours() + 2))
      }
    ] */// )
    var calendar = this.calendarInstance
    var router = this.$router
    this.calendarInstance.on('beforeCreateSchedule', function (event) {
      var triggerEventName = event.triggerEventName
      // console.log('triggerEventName', triggerEventName, event)
      const date = new Date(event.start)
      if (triggerEventName === 'dblclick' && (new Date(date.setHours(0, 0, 0, 0)) >= new Date(new Date().setHours(0, 0, 0, 0)))) {
        // console.log('clickSchedule', new Date(event.start))
        /// console.log(date/* .toISOString() */, 'ss')
        router.push({ path: '/appointment/clock', query: { date: tm.baseTime(date, true)/* .slice(0, 10) */ /* date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() */ /* .toISOString() */ } })
      } else {
        calendar.render()
      }
    })
    this.month = this.calendarInstance.getDate().getMonth()
    this.year = this.calendarInstance.getDate().getFullYear()
    // console.log('ee', )
    /* if (!this.calendar) {
      this.getCalendar()
      // this.$emit('clicked', 'someValue')
    } else {
      this.calendarInstance.createSchedules(this.calendar)
    } */
    this.calendarInstance.clear()
    this.getCalendar()
  },
  beforeUnmount () {
    this.calendarInstance.off()
    this.calendarInstance.destroy()
  },
  methods: {
    move: function (e) {
      if (e === 1) {
        this.calendarInstance.next()
      } else if (e === -1) {
        this.calendarInstance.prev()
      } else {
        this.calendarInstance.today()
      }
      this.month = this.calendarInstance.getDate().getMonth()
      this.year = this.calendarInstance.getDate().getFullYear()
      this.calendarInstance.clear()
      /// console.log(this.$route.query.date, 'whu')
      this.getCalendar()
    },
    getCalendar: async function (e) {
      const start = new Date(this.calendarInstance.getDateRangeStart())
      const end = new Date(this.calendarInstance.getDateRangeEnd())
      // console.log('gg', start.toISOString(), end.toISOString())
      // console.log('gg') //start.toISOString(), end.toISOString())
      // body: JSON.stringify({ start: this.calendarInstance.getDateRangeStart(), end: this.calendarInstance.getDateRangeEnd() })
      fetch(`/api/getEvents/?start=${tm.baseTime(start)/* start.toISOString() */}&end=${tm.baseTime(end)/* end.toISOString() */}`, {
        headers: {
          AuthToken: this.idToken
        }
      })
        .then(response => response.json())
        .then(json => {
          var list = []
          for (let i = 0; i < json.length; i++) {
            if (json[i].recurrence) {
              const options = RRule.parseString(// RRule.fromString(
                json[i].recurrence[0]
              )
              // console.log(new Date('2021-04-25T00:00:00.000Z'), new Date(start), start, new Date(tm.baseTime(start)), 'wer')
              options.dtstart = new Date(json[i].start/* tm.baseTime(start) */) // new Date('2021-04-25T00:00:00.000Z')
              var rule = new RRule(options)
              /// * + ((24 * 60 * 60 * 1000) - 1000) */))// new Date(Date.parse(cal[i].end) - 1000)
              var between = rule.between(new Date(start), new Date(Date.parse(end) + ((24 * 60 * 60 * 1000) - 1000)))
              // console.log(rule.between(new Date(start), new Date(end)))
              // console.log(start, tm.baseTime(start), end, tm.baseTime(end), 'UU')
              /* var options = RRule.parseString(json[i].recurrence[0])
              options.dtstart = start
              console.log(json[i].recurrence[0], 'wwd')
              var rule = new RRule(options)
              console.log('papa', rule.between(start, end)[0]) */
              // console.log(rule.between(new Date(start), new Date(end)), 'eeee')
              /// /console.log(json[i].start.length > 10 ? '1' : '0')
              for (let j = 0; j < between.length; j++) {
                // const element = rule[i]
                /// console.log(tm.baseTime(new Date(json[i].end)) + ' || ' + tm.baseTime(new Date(json[i].start)) + ' || ' + tm.baseTime(new Date(between[j])), 'ggio')
                // start
                /* var st = ''
                if (json[i].start.length > 10) {
                  st = tm.baseTime(new Date(between[j])).slice(0, 10) + 'T' + tm.baseTime(new Date(json[i].start)).slice(11, 19) + '.000Z'
                  ///console.log(st, 'st')
                } else {
                  st = between[j]
                }
                // end
                var en = ''
                if (json[i].end.length > 10) {
                  en = tm.baseTime(new Date(between[j])).slice(0, 10) + 'T' + tm.baseTime(new Date(json[i].end)).slice(11, 19) + '.000Z'
                  ///console.log(en, 'en')
                } else {
                  en = between[j]
                }
                console.log('s|s', Date.parse(json[i].end) - Date.parse(json[i].start)) */// between[j]
                // var en
                list.push({
                  calendarId: '1',
                  nonWork: json[i].nonWork,
                  bgColor: json[i].category === 'time' ? '#9e5fff' : '#c91871',
                  category: json[i].category,
                  start: between[j], // st, // json[i].start.length > 10 ? /* 'json[i].start' */ new Date() /* between[j] */ : between[j], // .toISOString(),
                  end: json[i].end.length > 10 ? tm.baseTime(new Date(Date.parse(between[j]) + (Date.parse(json[i].end) - Date.parse(json[i].start)))) : between[j]// json[i].end.length > 10 ? /* 'json[i].end' */ new Date() /* between[j] */ : between[j]// .toISOString()
                })
              }
            } else {
              list.push({
                calendarId: '1',
                nonWork: json[i].nonWork,
                bgColor: json[i].category === 'time' ? '#9e5fff' : '#c91871',
                category: json[i].category,
                start: json[i].start,
                end: json[i].end
              })
            }
          }
          /// ///console.log('ss', list, json)
          this.$emit('updateCalendar', list)
          var listing = list.filter(e => e.nonWork === 0)
          this.calendarInstance.createSchedules(listing)
        })
    }
  }
}
</script>
