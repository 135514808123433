import { createStore } from 'vuex'

export default createStore({
  state: {
    head0: 'Welcome To',
    name: 'Snehdhara Women\'s Hospital',
    home: {
      head1: {
        a: 'Obstetrics',
        b: '&',
        c: 'Gynaecology'
      },
      head2: 'Follow of Gynae Endoscopic Surgery',
      dedicated: [
        ['1615836.png', 'Single dedicated center for all Obstetric and Gynaecological problems.'],
        ['3027383.png', 'State of the art healthcare facilities with modern equipments to support best treatments and innovations.'],
        ['1610219.png', 'Experienced and caring staff to provide excellent care.'],
        ['567024.png', 'Separate waiting room.'],
        ['1651602.png', 'Separate consultation areas.'],
        ['insurance.svg', 'Mediclaim facility available'],
        ['image2vector.svg', 'Emergency services available.']
      ],
      address: '2nd Floor, 237 to 243, City Gate, Next to Vishala Restaurant, NH-8, New Vasna, Ahmedabad - 380055',
      contact: '+91 8866574465',
      email: 'snehdhara.hospital@gmail.com'
    },
    mv: {
      mission: [
        'To provide a professional, honest and ethical approach to health care.',
        'To serve with kindness and compassion.',
        'To recognize unique and intrinsic value of each patient.',
        'To nurture and protect the health and well-being of women and child.'
      ],
      vision: 'To be one of the most trusted hospital by providing personalized care for best patient experience in consonant with international standards.',
      values: [
        'Our topmost priority  “PATIENT’S WELL-BEING”',
        'Adopt and encourage ethical practices.',
        'Provide a safe and comfortable working environment to all employees and associates.'
      ]
    },
    services: {
      list: [
        'Obstetrics',
        'Gynaecology',
        'Laparoscopy',
        'Infertility',
        'Sonography'
      ],
      obstetrics: [
        'Painless Vaginal Delivery',
        'Cesarean Section (C.S)',
        'Dilatation and Evacuation (D&E)',
        'Cervical Encerclage',
        'High Risk Pregnancy',
        '4 D Ultrasonography with Color Doppler',
        'Fetal Monitoring',
        'Antenatal Care',
        'Post Partum Contraception',
        'General Obstetrics'
      ],
      gynaecology: [
        'Menstrual Problems',
        'Vaginal Discharge',
        'Ovarian Cyst',
        'Myomectomy ( Fibroid )',
        'Congenital Anomaly',
        'Sling Surgery ( Uterine Prolapse )',
        'Urinary Problems',
        'Endometriosis',
        'Contraception And Family Planning',
        'Microsurgery'
      ],
      laparoscopy: [
        'Diagnostic and Operative Laparoscopy',
        'Total Laparoscopic Hysterectomy',
        'Laparoscopic Myomectomy ( fibroid )',
        'Laparoscopic Endometriosis Surgery',
        'Advance Laparoscopic Surgery',
        'Diagnostic and Operative Hysteroscopy',
        'Fertility Enhancing Laparoscopy'
      ],
      infertility: [
        'Male Infertility',
        'Female Infertility',
        'Ovulation Induction & Monitoring with Transvaginal Ultrasonography',
        'Intrauterine Insemination ( I.U.I. )',
        'Fertility Enhancing Laparoscopic Surgery'

      ],
      sonography: [
        'Obstetric Ultrasound with Color Doppler',
        'Transabdominal Sonography ( TAS )',
        'Transvaginal Sonography ( TVS )',
        'Ultrasonography Guided Procedures'
      ]
    },
    about: {
      main: 'Snehdhara Women\'s Hospital is established on one singular thought of "SERVICING MANKIND". It is located near Vishala Restaurant. Dr. Zaid Shirazi is the guiding force in managing Snehdhara Women\'s Hospital as a center of excellence in women\'s healthcare. Our Excellence in our track record is a reflection of skills, comprehensive healthcare, and strong infrastructure.',
      fogsi: [
        'Basic and Invasive Sonography',
        'Basic and Advanced Infertility',
        'Intra-Uterine Insemination Training Course',
        'PPH Drill Training courses at V.S Hospital Ahmedabad'
      ],
      experience: '13 years of experience after post-graduation which includes',
      experiences: [
        'Almost a decade of public service as HOD(Gynecology) at Civil Hospital - Siddhpur-Patan-Gujarat',
        'Can single-handedly treat and manage high-risk patients.',
        'Ex. Professor of CPS  course at Civil Hospital-Siddhpur-Patan- Gujarat',
        'Ex. Honorary Lecturer at Nursing college-Siddhpur',
        'Ex. Civil Surgeon at Civil Hospital-Siddhpur-Patan- Gujarat'
      ]
    },
    gallery: {},
    contact: {},
    count: 0,
    popUp: '',
    popUpType: '',
    fileName: ''
  },
  mutations: {
    increment (state, payload) {
      state.count = payload
    },
    popUp (state, payload) {
      state.popUp = payload.popUp
      state.popUpType = payload.popUpType
      state.fileName = payload.fileName
    }
  },
  actions: {
  },
  modules: {
  }
})
