<template>
  <div>
    <input
      class="inline-block w-12 h-12 mx-2 text-xl text-center align-middle border rounded outline-none focus:border-pinkish-500"
      ref="input"
      :disabled="isDisabled"
      v-model="model"
      maxlength="1"
      max="9"
      min="0"
      inputmode="decimal"
      :name="int"
      @input="handleOnChange"
      @keydown="handleOnKeyDown"
      @paste="handleOnPaste"
      @focus="handleOnFocus"
      @blur="handleOnBlur"
    />
    <!--<span v-if="!isLastChild && separator">{{ separator }}</span>-->
    <span v-if="isMiddle && separator">{{ separator }}</span>
  </div>
</template>

<script>
// :type="isInputNum ? 'number' : 'text'"
export default {
  props: {
    int: {
      type: Number
    },
    value: {
      type: String
    },
    separator: {
      type: String
    },
    focusStyle: {
      type: Object
    },
    focus: {
      type: Boolean
    },
    isDisabled: {
      type: Boolean
    },
    disabledStyle: {
      type: Object
    },
    hasErrored: {
      type: Boolean
    },
    errorStyle: {
      type: Object
    },
    shouldAutoFocus: {
      type: Boolean
    },
    isInputNum: {
      type: Boolean
    },
    isLastChild: {
      type: Boolean
    },
    isMiddle: {
      type: Boolean
    }
  },
  data () {
    return {
      model: this.value || ''
    }
  },
  mounted () {
    if (this.$refs.input && this.focus && this.shouldAutoFocus) {
      this.$refs.input.focus()
    }
  },
  watch: {
    value (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.model = newValue
      }
    },
    // whenever question changes, this function will run
    focus (newFocusValue, oldFocusValue) {
      // Check if focusedInput changed
      // Prevent calling function if input already in focus
      if (oldFocusValue !== newFocusValue && (this.$refs.input && this.focus)) {
        this.$refs.input.focus()
        this.$refs.input.select()
      }
    }
  },
  computed: {
    numValueLimits () {
      return this.isInputNum ? { min: 0, max: 9 } : {}
    }
  },
  methods: {
    handleOnChange () {
      if (!isNaN(parseInt(this.model))) {
        return this.$emit('onChange', this.model)
      } else {
        this.model = ''
      }
    },
    handleOnKeyDown (event) {
      return this.$emit('onKeyDown', event)
    },
    handleOnPaste (event) {
      return this.$emit('onPaste', event)
    },
    handleOnFocus (event) {
      this.$refs.input.select()
      return this.$emit('onFocus')
    },
    handleOnBlur (event) {
      return this.$emit('onBlur')
    }
  }
}
</script>

<style>
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>
