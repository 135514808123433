<template>
  <div class="w-full py-2">
    <div class="flex">
      <div class="w-1/4">
        <div class="relative mb-2">
          <div :class="tab === 0 ? 'ring-2 hover:ring ring-offset-2 ring-violet-500' : ''" class="flex hover:ring-1 ring-offset-2 ring-violet-500 items-center w-10 h-10 mx-auto text-lg text-white border-gray-200 rounded-full bg-violet-500">
            <span class="w-full text-center">
              <svg class="w-full fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path class="heroicon-ui" d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2zm14 8V5H5v6h14zm0 2H5v6h14v-6zM8 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 8a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
              </svg>
            </span>
          </div>
        </div>

        <div class="text-xs text-center md:text-base">Name</div>
      </div>

      <div class="w-1/4">
        <div class="relative mb-2">
          <div class="absolute flex items-center content-center align-middle align-center" style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
            <div class="items-center flex-1 w-full align-middle bg-gray-200 rounded align-center">
              <div class="w-0 py-1 rounded bg-violet-300" ref="1Prog" style="width: 0%;"></div>
            </div>
          </div>
          <div :class="(tab >= 1? 'text-white hover:ring-1 ring-offset-2 ring-violet-500 bg-violet-500' : 'bg-white border-2') + ' ' + (tab === 1 ? 'ring-2 hover:ring ring-offset-2 ring-violet-500' : '')" class="flex items-center w-10 h-10 mx-auto text-lg border-gray-200 rounded-full">
            <span class="w-full text-center">
              <svg class="w-full fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path class="heroicon-ui" d="M19 10h2a1 1 0 0 1 0 2h-2v2a1 1 0 0 1-2 0v-2h-2a1 1 0 0 1 0-2h2V8a1 1 0 0 1 2 0v2zM9 12A5 5 0 1 1 9 2a5 5 0 0 1 0 10zm0-2a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm8 11a1 1 0 0 1-2 0v-2a3 3 0 0 0-3-3H7a3 3 0 0 0-3 3v2a1 1 0 0 1-2 0v-2a5 5 0 0 1 5-5h5a5 5 0 0 1 5 5v2z"/>
              </svg>
            </span>
          </div>
        </div>

        <div class="text-xs text-center md:text-base">Calendar</div>
      </div>

      <div class="w-1/4">
        <div class="relative mb-2">
          <div class="absolute flex items-center content-center align-middle align-center" style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
            <div class="items-center flex-1 w-full align-middle bg-gray-200 rounded align-center">
              <div class="w-0 py-1 rounded bg-violet-300" ref="2Prog" style="width: 0%;"></div>
            </div>
          </div>

          <div :class="(tab >= 2? 'text-white hover:ring-1 ring-offset-2 ring-violet-500 bg-violet-500' : 'bg-white border-2') + ' ' + (tab === 2 ? 'ring-2 hover:ring ring-offset-2 ring-violet-500' : '')" class="flex items-center w-10 h-10 mx-auto text-lg border-gray-200 rounded-full">
            <span class="w-full text-center">
              <svg class="w-full" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="24" height="24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
              </svg>
            </span>
          </div>
        </div>

        <div class="text-xs text-center md:text-base">Clock</div>
      </div>

      <div class="w-1/4">
        <div class="relative mb-2">
          <div class="absolute flex items-center content-center align-middle align-center" style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
            <div class="items-center flex-1 w-full align-middle bg-gray-200 rounded align-center">
              <div class="w-0 py-1 rounded bg-violet-300" ref="3Prog" style="width: 0%;"></div>
            </div>
          </div>

          <div :class="(tab >= 3? 'text-white hover:ring-1 ring-offset-2 ring-violet-500 bg-violet-500' : 'bg-white border-2') + ' ' + (tab === 3 ? 'ring-2 hover:ring ring-offset-2 ring-violet-500' : '')" class="flex items-center w-10 h-10 mx-auto text-lg border-gray-200 rounded-full">
            <span class="w-full text-center">
              <svg class="w-full fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path class="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-2-2a1 1 0 0 1 1.4-1.42z"/>
              </svg>
            </span>
          </div>
        </div>

        <div class="text-xs text-center md:text-base">Remark</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['tab'],
  watch: {
    tab: function (e, s) {
      // if (!(e <= 0 || e >= 3)) {
      let progress = 0
      var direction = 1
      if (e < s) {
        direction = -1
        progress = 100
        e += 1
      }
      var style = this.$refs[e + 'Prog'].style
      var progressInterval = setInterval(function () {
        progress += direction
        style.width = progress + '%'
        if (progress >= 100) {
          clearInterval(progressInterval)
        }
      }, 2)
      // }
    }
  }
}
</script>

<style>

</style>
