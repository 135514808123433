<template>
  <section id="about" class="relative px-4 lg:px-20 py-28 text-left">
    <div class="container px-4 mx-auto">
      <div class="flex flex-wrap items-center">
        <div
          class="w-full px-4 ml-auto mr-auto mx-4 lg:w-6/12"
          data-aos="fade-right"
        >
          <div class="lg:pr-12">
            <!-- <h3 class="text-4xl font-bold uppercase">Info :</h3> -->
            <!-- <img src="img/247.png" alt="24/7" class="h-24" /> -->

            <ul class="my-6 list-none">
              <li class="py-2">
                <h6 class="flex pt-4 text-xl uppercase">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-3 text-pinkish-500" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                  </svg>
                  Address :
                </h6>
                <p class="pl-10 text-lg leading-relaxed">
                  {{ data.address }}
                </p>
              </li>
              <li class="py-2">
                <h6 class="flex pt-4 text-xl uppercase">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-3 text-pinkish-500" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                  </svg>Contact :
                </h6>
                <a :href="'tel:' + data.contact" class="pl-10 text-lg leading-relaxed">{{ data.contact }}</a>
              </li>
              <li class="py-2">
                <h6 class="flex pt-4 text-xl uppercase">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-3 text-pinkish-500" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z" clip-rule="evenodd" />
                  </svg>Email :
                </h6>
                <a :href="'mailto:' + data.email" class="pl-10 text-lg leading-relaxed">{{ data.email }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="w-full lg:w-6/12" data-aos="fade-left">
          <!--<img
            alt="Map"
            class="max-w-full rounded-lg shadow-lg"
            src="../../assets/map.jpg"
             class="top-0 left-0 w-full h-full rounded-xl"
          />
          <img src="../../assets/map.jpg" alt="map" class="top-0 left-0 rounded-xl shadow-lg w-full h-full" />
          -->
          <iframe class="top-0 left-0 rounded-xl shadow-lg w-full h-96"  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.84078201861!2d72.5342050153847!3d22.992881623205975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e8578951e2271%3A0x4c5d71ccaf74a762!2sSNEHDHARA%20WOMEN&#39;S%20HOSPITAL!5e0!3m2!1sen!2sin!4v1618295494164!5m2!1sen!2sin" allowfullscreen="" loading="lazy"></iframe>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Map',
  computed: {
    data () {
      return this.$store.state.home
    }
  }
}
</script>

<style>
</style>
