<template>
  <Header />
  <main>
    <slot/>
  </main>
  <div class="fixed z-50 bottom-4 right-4">
    <!--<span class="relative inline-flex rounded-md shadow-sm">
        <button type="button" class="inline-flex items-center px-4 py-2 border border-purple-400 text-base leading-6 font-medium rounded-md text-purple-800 bg-white hover:text-purple-700 focus:border-purple-300 transition ease-in-out duration-150">
          Transactions
        </button>
        <span class="flex absolute h-full w-full top-0 right-0 -mt-1 -mr-1">
          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75"></span>
        </span>
      </span>-->
    <router-link to="/appointment" class='pulsingButton flex items-center justify-center h-12 px-4 leading-none text-white rounded-full font-bold bg-violet-500'>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" class="w-14 h-14 fill-current text-white" width="840.000000pt" height="557.000000pt"
           viewBox="0 0 840.000000 557.000000" preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,557.000000) scale(0.100000,-0.100000)" fill="#ffffff" stroke="none">
          <path d="M2500 5143 c-19 -10 -45 -34 -58 -53 l-23 -34 3 -352 3 -352 25 -27
c55 -59 131 -72 191 -32 67 45 69 54 69 420 0 257 -3 335 -14 361 -29 70 -127
105 -196 69z" />
          <path d="M4880 5143 c-19 -10 -45 -34 -58 -53 l-23 -34 3 -352 3 -352 25 -27
c39 -42 78 -58 128 -52 53 6 91 31 114 78 16 30 18 69 18 362 0 257 -3 335
-14 361 -29 70 -127 105 -196 69z" />
          <path d="M2037 4546 c-84 -30 -148 -92 -190 -184 l-22 -47 0 -1385 0 -1385 26
-55 c37 -80 81 -125 157 -162 l67 -33 912 -3 913 -3 0 151 0 150 -887 2 -888
3 -3 1038 -2 1037 1635 0 1635 0 0 -295 0 -295 150 0 151 0 -3 613 c-3 609 -3
612 -26 668 -26 65 -96 139 -161 171 -37 19 -67 24 -152 26 l-107 4 -4 -114
c-4 -128 -22 -179 -87 -242 -63 -61 -115 -81 -206 -81 -95 0 -150 22 -208 81
-68 68 -81 104 -85 237 l-4 117 -893 0 -893 0 -3 -112 c-5 -126 -21 -172 -85
-239 -59 -61 -117 -84 -209 -84 -95 0 -150 22 -208 81 -68 68 -81 104 -85 237
l-4 117 -97 0 c-54 -1 -112 -7 -134 -14z" />
          <path d="M2420 3075 l0 -295 295 0 295 0 0 295 0 295 -295 0 -295 0 0 -295z" />
          <path d="M3310 3075 l0 -295 295 0 295 0 0 295 0 295 -295 0 -295 0 0 -295z" />
          <path d="M4200 3075 l0 -295 295 0 295 0 0 295 0 295 -295 0 -295 0 0 -295z" />
          <path d="M5220 2766 c-265 -42 -485 -152 -669 -337 -277 -277 -400 -672 -330
-1057 31 -166 121 -368 224 -502 255 -329 664 -506 1066 -461 287 33 520 144
718 342 277 277 400 672 330 1057 -20 105 -78 266 -130 357 -179 311 -458 516
-804 590 -101 21 -303 27 -405 11z m407 -315 c242 -70 439 -232 556 -458 75
-145 92 -220 91 -403 0 -171 -11 -231 -67 -355 -120 -269 -354 -459 -635 -517
-124 -26 -308 -21 -421 12 -144 41 -285 125 -392 232 -80 81 -127 150 -179
261 -54 116 -70 184 -77 327 -4 102 -1 143 15 222 73 353 362 633 720 698 82
15 308 4 389 -19z" />
          <path d="M5845 1902 c-16 -11 -147 -137 -290 -281 -143 -144 -264 -261 -270
-261 -6 0 -82 72 -170 161 -88 88 -172 167 -187 175 -56 29 -127 13 -177 -39
-25 -28 -38 -97 -27 -143 8 -29 398 -433 470 -485 31 -23 49 -29 92 -29 46 0
58 5 101 39 97 78 661 655 668 684 15 57 0 117 -38 155 -30 30 -44 36 -89 39
-40 3 -60 0 -83 -15z" />
          <path d="M2420 2185 l0 -295 295 0 295 0 0 295 0 295 -295 0 -295 0 0 -295z" />
          <path d="M3310 2185 l0 -295 295 0 295 0 0 295 0 295 -295 0 -295 0 0 -295z" />
        </g>
      </svg>
      <p class="text-left">Book An<br/>Appointment</p></router-link>
  </div>
  <Footer />
</template>

<script>
import Header from '../components/Layout/Header.vue'
import Footer from '../components/Layout/Footer.vue'
export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
}
</script>
