<template>
  <div class="flex justify-center">
    <SingleOtpInput
      v-for="(item, i) in numInputs"
      :key="i"
      :int="i"
      :focus="activeInput === i"
      :value="otp[i]"
      @onChange="handleOnChange"
      @onKeyDown="handleOnKeyDown"
      @onPaste="handleOnPaste"
      @onFocus="handleOnFocus(i);"
      @onBlur="handleOnBlur"
      :separator="separator"
      :focusStyle="focusStyle"
      :isLastChild="i === numInputs - 1"
      :isMiddle="(i + 1) === Math.ceil(numInputs/2)"
      :isDisabled="isDisabled"
      :disabledStyle="disabledStyle"
      :hasErrored="hasErrored"
      :errorStyle="errorStyle"
      :shouldAutoFocus="shouldAutoFocus"
      :isInputNum="isInputNum"
    />
  </div>
</template>

<script>
import SingleOtpInput from './SingleOtpInput.vue'

// keyCode constants
const BACKSPACE = 8
const LEFT_ARROW = 37
const RIGHT_ARROW = 39
const DELETE = 46

export default {
  components: {
    SingleOtpInput
  },
  props: {
    numInputs: {
      default: 0
    },
    separator: {
      type: String,
      default: '-'
    },
    focusStyle: {
      type: Object
    },
    disabledStyle: {
      type: Object
    },
    isInputNum: {
      type: Boolean
    },
    isDisabled: {
      default: false
    },
    shouldAutoFocus: {
      type: Boolean,
      default: false
    },
    hasErrored: {
      type: Boolean,
      default: false
    },
    errorStyle: {
      type: Object
    }
  },
  data () {
    return {
      activeInput: 0,
      otp: []
    }
  },
  methods: {
    isStyleObject (obj) {
      return typeof obj === 'object'
    },

    handleOnFocus (index) {
      this.activeInput = index
    },

    handleOnBlur () {
      this.activeInput = -1
    },

    // Helper to return OTP from input
    getOtp () {
      // if (this.otp.length === this.numInputs) {
      this.$emit('otp', this.otp.join('').substring(0, this.numInputs))
      // console.log(this.otp.join('').substring(0, 4))
      // }
    },

    // Focus on input by index
    focusInput (input) {
      this.activeInput = Math.max(Math.min(this.numInputs - 1, input), 0)
    },

    // Focus on next input
    focusNextInput () {
      this.focusInput(this.activeInput + 1)
    },

    // Focus on previous input
    focusPrevInput () {
      this.focusInput(this.activeInput - 1)
    },

    // Change OTP value at focused input
    changeCodeAtFocus (value) {
      this.otp[this.activeInput] = value
      this.getOtp()
    },

    // Handle pasted OTP
    handleOnPaste (event) {
      event.preventDefault()
      const pastedData = event.clipboardData
        .getData('text/plain')
        .slice(0, this.numInputs - this.activeInput)
        .split('')

      // Paste data from focused input onwards
      const currentCharsInOtp = this.otp.slice(0, this.activeInput)
      const combinedWithPastedData = currentCharsInOtp.concat(pastedData)
      this.otp = combinedWithPastedData.slice(0, this.numInputs)
      // for (let pos = 0; pos < this.numInputs; ++pos) {
      //   if (pos >= this.activeInput && pastedData.length > 0) {
      //     this.otp[pos] = pastedData.shift();
      //   }
      // }
      this.getOtp()
    },
    handleOnChange (value) {
      this.changeCodeAtFocus(value)
      this.focusNextInput()
    },
    // Handle cases of backspace, delete, left arrow, right arrow
    handleOnKeyDown (event) {
      switch (event.keyCode) {
        case BACKSPACE:
          event.preventDefault()
          this.changeCodeAtFocus('')
          this.focusPrevInput()
          break
        case DELETE:
          event.preventDefault()
          this.changeCodeAtFocus('')
          break
        case LEFT_ARROW:
          event.preventDefault()
          this.focusPrevInput()
          break
        case RIGHT_ARROW:
          event.preventDefault()
          this.focusNextInput()
          break
        default:
          break
      }
    }
  }
}
</script>
