<template>
  <div
    class="flex items-center pb-40 h-screen -mb-20"
  >
    <div class="flex items-center lg:container mx-auto">
      <div class="lg:w-6/12 z-20">
        <!--<p class="text-violet-400">{{ data.head0 }} <span class="font-bold">{{ $store.state.name }}</span></p>-->
        <h1 class="text-lg mb-2 text-violet-500 tracking-tight font-extrabold text-gray-900 sm:text-lg md:text-lg">
          <img src="../../assets/logo.png" alt="logo" class="h-64 mx-auto mb-4" />
          <div class="mb-3">
            <p class="text-md">{{$store.state.head0}}</p>
            <p class="text-5xl">Snehdhara<br/><span class="text-yellow-500">Women's Hospital</span></p>
          </div>

          <span class="block leading-none text-2xl font-mono font-thin inline font">{{ data.head1.a }} {{ data.head1.b }} {{ data.head1.c }}</span>
        </h1>
      <!--<h6 class="text-violet-400 font-bold">{{ data.head2 }}</h6>
      <h6 class="text-blue-600 tracking-wider font-extrabold">+91 545 545</h6>-->
      </div>
      <div class="lg:w-6/12">
        <!-- style="z-index:-1" -->
        <!--<div data-tilt data-tilt-glare data-tilt-max-glare="0.8" data-tilt-scale="1.1" class="js-tilt">
          <img src="img/hero.webp" alt="hero" class="top-0 left-0 w-full h-full lg:rounded-3xl" />
        </div>-->
        <div class="hidden lg:block">
          <img src="../../assets/mom.jpg" alt="hero" class="top-0 left-0 w-full h-full lg:rounded-3xl" />
        </div>
        <img src="../../assets/mom.jpg" alt="hero" class="lg:hidden absolute lg:relative top-0 left-0 w-full h-full object-cover lg:rounded-3xl" style="z-index:-1" />
        <!--

  <div data-tilt data-tilt-glare data-tilt-max-glare="0.8" data-tilt-scale="1.1" class="hidden lg:block js-tilt">
          <img src="img/hero.webp" alt="hero" class="top-0 left-0 w-full h-full lg:rounded-3xl" />
        </div>
        <img src="img/hero.webp" alt="hero" class="lg:hidden absolute lg:relative top-0 left-0 w-full h-full lg:rounded-3xl" style="z-index:-1" />
-->
        <div class="lg:hidden shadow-2xl absolute top-0 left-0 w-full h-full opacity-75 bg-white" style="z-index:-1" />
      </div>
      <!--<div class="lg:w-6/12">
        <img src="img/hero.webp" alt="hero" class="absolute lg:relative top-0 left-0 w-full h-full lg:rounded-3xl" style="z-index:-1" />
        <div class="lg:hidden absolute top-0 left-0 w-full h-full opacity-75 bg-white" style="z-index:-1" />
      </div>-->
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    data () {
      return this.$store.state.home
    }
  }
}
</script>
