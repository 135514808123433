<template>
  <div class="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
    <div class="relative py-3 sm:max-w-xl sm:mx-auto">
      <div class="absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-pinkish-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
      <div class="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
        <div class="max-w-md mx-auto">
          <router-link to="/" class="flex items-center justify-center font-bold text-violet-500 text-2xl">
            <img src="../assets/logo.png" alt="logo" class="h-16" />
            &nbsp;&nbsp;Snehdhara&nbsp;<span class="text-yellow-500">Women's Hospital</span>
          </router-link>
          <div class="divide-y divide-gray-200">
            <div class="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
              <!--<p>An advanced online playground for Tailwind CSS, including support for things like:</p>-->
              <!--{{timeStamp}}-->
              Hi {{name}}, your slot is booked at <br/>{{date}}<br/>By No.{{phone}} <span v-if="remark">with following remark:<br/>"{{remark}}"</span>
              <!--<p>Tk</p>-->
            </div>
            <div class="pt-6 text-base leading-6 font-bold sm:text-lg sm:leading-7">
              <p>Thanks for choosing <span class="text-violet-500">Snehdhara</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import tm from '../utils/timeSlots'
export default {
  props: ['timeStamp', 'phone', 'name', 'selectTime', 'remark'],
  computed: {
    date: function (e) {
      // tm
      return new Date(this.selectTime).toDateString() + ' ' + new Date(this.selectTime).toLocaleString([], { hour: '2-digit', minute: '2-digit' })// tm.baseTime(new Date(this.selectTime).toDateString())// new Date(this.selectTime).toString()
    }
  }
}
</script>

<style>

</style>
