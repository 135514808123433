<template>
  <Layout>
    <div class="bg-pinkish-500">
      <div class="text-left container mx-auto px-4 pt-12 font-bold text-2xl text-white">
        Gallery
        <div class="bg-yellow-500 w-8 h-1"/>
      </div>
    </div>
    <div
      class="absolute top-0 left-0 right-0 top-auto w-full"
    >
      <svg
        class="absolute top-0"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          class="fill-current text-pinkish-500"
          points="2560 0 0 100 0 0"
        ></polygon>
      </svg>
    </div>
    <div class="container mx-auto px-4 py-20 pb-24">
      <viewer :images="img" class="gallery-grid">
        <img v-for="(item, index) in img" :key="index" :src="'/img/gallery/'+ item +'.webp'" loading="lazy" alt="img" />
      </viewer>
    </div>
  </Layout>
</template>

<script>
import Layout from '../layout/default.vue'
export default {
  components: {
    Layout
  },
  data: function () {
    return {
      img: [
        '_DSC0622',
        '_DSC0628',
        '_DSC0633',
        '_DSC0639',
        '_DSC0642',
        '_DSC0643',
        // '_DSC0645',
        '_DSC0647',
        // '_DSC0648',
        '_DSC0649',
        '_DSC0713',
        '_DSC0701',
        '_DSC0706',
        'hero',
        '_DSC0699',
        '_DSC0657',
        '_DSC0670',
        '_DSC0671',
        // '_DSC0678',
        '_DSC0702',
        '_DSC0680',
        // '_DSC0684',
        '_DSC0004',
        '_DSC0724'
      ]
    }
  }
}
</script>
