<template>
  <footer class="relative text-left bg-pinkish-500">
    <div
      class="absolute w-full"
    >
      <svg
        class="absolute bottom-0"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          class="fill-current text-pinkish-500"
          points="0 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
    <!--<div class="relative py-10 text-white bg-pinkish-500">
      cxzxv
    </div>-->
    <div class="container px-4 mx-auto text-white">

    </div>
    <div class="container px-4 mx-auto text-white">
      <div class="flex flex-wrap">
        <div class="w-full px-4 lg:w-6/12">
          <ul class="flex items-center space-x-2">
            <li class="text-2xl">
              Visitors:
            </li>
            <li v-for="(e,i) in count" :key="i" class="bg-white text-pinkish-500 px-2 py-1 text-2xl rounded-md">
              {{e}}
            </li>
          </ul>
        <!--
          <h4 class="text-3xl font-semibold">Follow us on social media</h4>
          <h5 class="mt-0 mb-2 text-lg text-white">
            Find us on any of these platforms, we respond 1-2 business days.
          </h5>
          <div class="mt-6">
            <button
              class="items-center justify-center w-10 h-10 p-3 mr-2 font-normal text-blue-400 bg-white rounded-full shadow-lg outline-none align-center focus:outline-none"
              type="button"
            >
              <i class="flex text-snehdhara-8 fab fa-twitter"></i></button
            ><button
              class="items-center justify-center w-10 h-10 p-3 mr-2 font-normal text-blue-600 bg-white rounded-full shadow-lg outline-none align-center focus:outline-none"
              type="button"
            >
              <i
                class="flex text-snehdhara-8 fab fa-facebook-square"
              ></i>
            </button>
            <button
              class="items-center justify-center w-10 h-10 p-3 mr-2 font-normal text-pink-400 bg-white rounded-full shadow-lg outline-none align-center focus:outline-none"
              type="button"
            >
              <i class="flex text-snehdhara-8 fab fa-linkedin"></i>
            </button>
          </div>-->
        </div>
      </div>
      <hr class="my-6 border-white" />
      <div
        class="flex flex-wrap items-center justify-center md:justify-between"
      >
        <div class="w-full px-4 mx-auto text-center md:w-4/12">
          <div class="pb-4 text-sm font-semibold text-white">
            Copyright © {{getFullYear}}
            <!--<ul class="flex items-center space-x-2">
              <li class="text-2xl">
                Visitors:
              </li>
              <li v-for="(e,i) in count" :key="i" class="bg-white text-pinkish-500 px-2 py-1 text-2xl rounded-md">
                {{e}}
              </li>
            </ul>-->
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  computed: {
    count () {
      return (this.$store.state.count + '').padStart(6, '0').split('')
    },
    getFullYear () {
      return new Date().getFullYear()
    }
  }
}
</script>

<style>

</style>
