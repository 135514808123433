<template>
  <Layout>
    <transition >
    </transition>
    <!--<div class="modal-overlay absolute w-full h-full bg-black opacity-25 top-0 left-0 cursor-pointer"></div>-->
    <!--<div class="modal opacity-0 pointer-events-none absolute w-full h-full top-0 left-0 flex items-center justify-center">

      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div class="absolute w-1/2 h-32 bg-white rounded-sm shadow-lg flex items-center justify-center text-2xl">
        🚀
      </div>
    </div>-->
    <!--<div class="flex items-end justify-center absolute left-0 top-0 right-0 text-center sm:block sm:p-0 z-50">
      <div class="min-h-screen absolute bg-gray-500 min bg-opacity-75 transition-opacity z-50"></div>
      <div class="w-1/2 h-32 bg-white rounded-md shadow-lg flex items-center justify-center z-40">
        asdf
      </div>
    </div>-->
    <div v-appear:top="true" class="fixed top-0 w-full z-50 h-full max-h-screen" v-if="$store.state.popUpType">
      <div class="fixed top-0 bg-gray-500 opacity-75 h-full w-full" />
      <div class="text-red-500 flex items-center h-full justify-center">
        <div class="bg-white rounded-lg p-5 z-50 w-11/12 sm:w-8/12 lg:w-1/2">

          <div class="flex justify-between w-full text-violet-500 text-xl font-bold pb-4">
            <button @click="$store.commit('popUp', { popUp: '', popUpType: '', fileName: '' })">
              <svg class="w-5 h-5 hover:text-pinkish-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
              </svg>
            </button>{{$store.state.fileName}}
            <button @click="$store.commit('popUp', { popUp: '', popUpType: '', fileName: '' })">
              <svg class="w-5 h-5 hover:text-pinkish-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>
          <div class="flex items-center justify-center">
            <div class="text-blue-600" v-if="$store.state.popUpType === 'txt' || $store.state.popUpType === 'html'" v-html="$store.state.popUp"/>
            <img v-else :src="$store.state.popUp" alt="img" class="rounded-lg">
          </div>
        </div>
      </div>
    </div>
    <Hero/>
    <Facilities/>
    <!--<Doctors />
    <Contact />-->
    <Map />
    <!--<p class="mt-40">{{ $t("message") }}</p>-->
    <!--<router-link to="/contact" class="fixed z-50 bottom-4 right-4 px-20 py-3 text-base font-bold border-2 rounded-full text-white bg-pinkish-500 hover:border-transparent hover:bg-yellow-500 hover:text-pinkish-500">
      <div class="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75"></div>
      Contact Us
    </router-link>-->
  </Layout>
</template>

<script>
// @ is an alias to /src
import Layout from '../layout/default.vue'
import Hero from '../components/Home/Hero.vue'
import Facilities from '../components/Home/Facilities.vue'
// import Doctors from '../components/Home/Doctors.vue'
// import Contact from '../components/Home/Contact.vue'
import Map from '../components/Home/Map.vue'
import VanillaTilt from 'vanilla-tilt'

export default {
  mounted () {
    const elements = document.querySelectorAll('.js-tilt')
    VanillaTilt.init(elements)
    // VanillaTilt.init(this.$refs.jsTilt)
    window.addEventListener('click', () => {
      if (this.$store.state.popUp) {
        this.$store.commit('popUp', { popUp: '', popUpType: '', fileName: '' })
      }
    })
  },
  components: {
    Layout,
    Hero,
    Facilities,
    /* Doctors,
    Contact, */
    Map
  }/*,
  computed:{
    popUp: function (e) {
      popUp: '',
    popUpType: ''
    if (condition) {

    }
      return this.$store.state.popUp
    }
  } */
}
</script>
